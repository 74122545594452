const WorldMapList =[
  {key: 1, text: "Afghanistan", value: "AF"},
  {key: 2, text: "Åland Islands", value: "AX"},
  {key: 3, text: "Albania", value: "AL"},
  {key: 4, text: "Algeria", value: "DZ"},
  {key: 5, text: "American Samoa", value: "AS"},
  {key: 6, text: "AndorrA", value: "AD"},
  {key: 7, text: "Angola", value: "AO"},
  {key: 8, text: "Anguilla", value: "AI"},
  {key: 9, text: "Antarctica", value: "AQ"},
  {key: 10, text: "Antigua and Barbuda", value: "AG"},
  {key: 11, text: "Argentina", value: "AR"},
  {key: 12, text: "Armenia", value: "AM"},
  {key: 13, text: "Aruba", value: "AW"},
  {key: 14, text: "Australia", value: "AU"},
  {key: 15, text: "Austria", value: "AT"},
  {key: 16, text: "Azerbaijan", value: "AZ"},
  {key: 17, text: "Bahamas", value: "BS"},
  {key: 18, text: "Bahrain", value: "BH"},
  {key: 19, text: "Bangladesh", value: "BD"},
  {key: 20, text: "Barbados", value: "BB"},
  {key: 21, text: "Belarus", value: "BY"},
  {key: 22, text: "Belgium", value: "BE"},
  {key: 23, text: "Belize", value: "BZ"},
  {key: 24, text: "Benin", value: "BJ"},
  {key: 25, text: "Bermuda", value: "BM"},
  {key: 26, text: "Bhutan", value: "BT"},
  {key: 27, text: "Bolivia", value: "BO"},
  {key: 28, text: "Bosnia and Herzegovina", value: "BA"},
  {key: 29, text: "Botswana", value: "BW"},
  {key: 30, text: "Bouvet Island", value: "BV"},
  {key: 31, text: "Brazil", value: "BR"},
  {key: 32, text: "British Indian Ocean Territory", value: "IO"},
  {key: 33, text: "Brunei Darussalam", value: "BN"},
  {key: 34, text: "Bulgaria", value: "BG"},
  {key: 35, text: "Burkina Faso", value: "BF"},
  {key: 36, text: "Burundi", value: "BI"},
  {key: 37, text: "Cambodia", value: "KH"},
  {key: 38, text: "Cameroon", value: "CM"},
  {key: 39, text: "Canada", value: "CA", lat: 0, lng:0},
  {key: 40, text: "Cape Verde", value: "CV"},
  {key: 41, text: "Cayman Islands", value: "KY"},
  {key: 42, text: "Central African Republic", value: "CF"},
  {key: 43, text: "Chad", value: "TD"},
  {key: 44, text: "Chile", value: "CL"},
  {key: 45, text: "China", value: "CN", lat: 0, lng:0},
  {key: 46, text: "Christmas Island", value: "CX"},
  {key: 47, text: "Cocos (Keeling) Islands", value: "CC"},
  {key: 48, text: "Colombia", value: "CO"},
  {key: 49, text: "Comoros", value: "KM"},
  {key: 50, text: "Congo", value: "CG"},
  {key: 51, text: "Congo, The Democratic Republic of the", value: "CD"},
  {key: 52, text: "Cook Islands", value: "CK"},
  {key: 53, text: "Costa Rica", value: "CR"},
  {key: 54, text: "Cote D'Ivoire", value: "CI"},
  {key: 55, text: "Croatia", value: "HR"},
  {key: 56, text: "Cuba", value: "CU"},
  {key: 57, text: "Cyprus", value: "CY"},
  {key: 58, text: "Czech Republic", value: "CZ"},
  {key: 59, text: "Denmark", value: "DK"},
  {key: 60, text: "Djibouti", value: "DJ"},
  {key: 61, text: "Dominica", value: "DM"},
  {key: 62, text: "Dominican Republic", value: "DO"},
  {key: 63, text: "Ecuador", value: "EC"},
  {key: 64, text: "Egypt", value: "EG", lat: 27, lng:30},
  {key: 65, text: "El Salvador", value: "SV"},
  {key: 66, text: "Equatorial Guinea", value: "GQ"},
  {key: 67, text: "Eritrea", value: "ER"},
  {key: 68, text: "Estonia", value: "EE"},
  {key: 69, text: "Ethiopia", value: "ET"},
  {key: 70, text: "Falkland Islands (Malvinas)", value: "FK"},
  {key: 71, text: "Faroe Islands", value: "FO"},
  {key: 72, text: "Fiji", value: "FJ"},
  {key: 73, text: "Finland", value: "FI"},
  {key: 74, text: "France", value: "FR", lat: 46, lng:2},
  {key: 75, text: "French Guiana", value: "GF"},
  {key: 76, text: "French Polynesia", value: "PF"},
  {key: 77, text: "French Southern Territories", value: "TF"},
  {key: 78, text: "Gabon", value: "GA"},
  {key: 79, text: "Gambia", value: "GM"},
  {key: 80, text: "Georgia", value: "GE"},
  {key: 81, text: "Germany", value: "DE", lat: 0, lng:0},
  {key: 82, text: "Ghana", value: "GH"},
  {key: 83, text: "Gibraltar", value: "GI"},
  {key: 84, text: "Greece", value: "GR"},
  {key: 85, text: "Greenland", value: "GL"},
  {key: 86, text: "Grenada", value: "GD"},
  {key: 87, text: "Guadeloupe", value: "GP"},
  {key: 88, text: "Guam", value: "GU"},
  {key: 89, text: "Guatemala", value: "GT"},
  {key: 90, text: "Guernsey", value: "GG"},
  {key: 91, text: "Guinea", value: "GN"},
  {key: 92, text: "Guinea-Bissau", value: "GW"},
  {key: 93, text: "Guyana", value: "GY"},
  {key: 94, text: "Haiti", value: "HT"},
  {key: 95, text: "Heard Island and Mcdonald Islands", value: "HM"},
  {key: 96, text: "Holy See (Vatican City State)", value: "VA"},
  {key: 97, text: "Honduras", value: "HN"},
  {key: 98, text: "Hong Kong", value: "HK"},
  {key: 99, text: "Hungary", value: "HU"},
  {key: 100, text: "Iceland", value: "IS"},
  {key: 101, text: "India", value: "IN", lat: 0, lng:0},
  {key: 102, text: "Indonesia", value: "ID"},
  {key: 103, text: "Iran, Islamic Republic Of", value: "IR"},
  {key: 104, text: "Iraq", value: "IQ"},
  {key: 105, text: "Ireland", value: "IE"},
  {key: 106, text: "Isle of Man", value: "IM"},
//   {key: 107, text: "Israel", value: "IL"},
  {key: 108, text: "Italy", value: "IT", lat: 0, lng:0},
  {key: 109, text: "Jamaica", value: "JM"},
  {key: 110, text: "Japan", value: "JP", lat: 0, lng:0},
  {key: 111, text: "Jersey", value: "JE"},
  {key: 112, text: "Jordan", value: "JO"},
  {key: 113, text: "Kazakhstan", value: "KZ"},
  {key: 114, text: "Kenya", value: "KE"},
  {key: 115, text: "Kiribati", value: "KI"},
  {key: 116, text: "Korea, Democratic People'S Republic of", value: "KP"},
  {key: 117, text: "Korea, Republic of", value: "KR"},
  {key: 118, text: "Kuwait", value: "KW"},
  {key: 119, text: "Kyrgyzstan", value: "KG"},
  {key: 120, text: "Lao People'S Democratic Republic", value: "LA"},
  {key: 121, text: "Latvia", value: "LV"},
  {key: 122, text: "Lebanon", value: "LB"},
  {key: 123, text: "Lesotho", value: "LS"},
  {key: 124, text: "Liberia", value: "LR"},
  {key: 125, text: "Libyan Arab Jamahiriya", value: "LY"},
  {key: 126, text: "Liechtenstein", value: "LI"},
  {key: 127, text: "Lithuania", value: "LT"},
  {key: 128, text: "Luxembourg", value: "LU"},
  {key: 129, text: "Macao", value: "MO"},
  {key: 130, text: "Macedonia, The Former Yugoslav Republic of", value: "MK"},
  {key: 131, text: "Madagascar", value: "MG"},
  {key: 132, text: "Malawi", value: "MW"},
  {key: 133, text: "Malaysia", value: "MY"},
  {key: 134, text: "Maldives", value: "MV"},
  {key: 135, text: "Mali", value: "ML"},
  {key: 136, text: "Malta", value: "MT"},
  {key: 137, text: "Marshall Islands", value: "MH"},
  {key: 138, text: "Martinique", value: "MQ"},
  {key: 139, text: "Mauritania", value: "MR"},
  {key: 140, text: "Mauritius", value: "MU"},
  {key: 141, text: "Mayotte", value: "YT"},
  {key: 142, text: "Mexico", value: "MX"},
  {key: 143, text: "Micronesia, Federated States of", value: "FM"},
  {key: 144, text: "Moldova, Republic of", value: "MD"},
  {key: 145, text: "Monaco", value: "MC"},
  {key: 146, text: "Mongolia", value: "MN"},
  {key: 147, text: "Montserrat", value: "MS"},
  {key: 148, text: "Morocco", value: "MA", lat: 32, lng:-5},
  {key: 149, text: "Mozambique", value: "MZ"},
  {key: 150, text: "Myanmar", value: "MM"},
  {key: 151, text: "Namibia", value: "NA"},
  {key: 152, text: "Nauru", value: "NR"},
  {key: 153, text: "Nepal", value: "NP"},
  {key: 154, text: "Netherlands", value: "NL"},
  {key: 155, text: "Netherlands Antilles", value: "AN"},
  {key: 156, text: "New Caledonia", value: "NC"},
  {key: 157, text: "New Zealand", value: "NZ"},
  {key: 158, text: "Nicaragua", value: "NI"},
  {key: 159, text: "Niger", value: "NE"},
  {key: 160, text: "Nigeria", value: "NG"},
  {key: 161, text: "Niue", value: "NU"},
  {key: 162, text: "Norfolk Island", value: "NF"},
  {key: 163, text: "Northern Mariana Islands", value: "MP"},
  {key: 164, text: "Norway", value: "NO"},
  {key: 165, text: "Oman", value: "OM"},
  {key: 166, text: "Pakistan", value: "PK"},
  {key: 167, text: "Palau", value: "PW"},
  {key: 168, text: "Palestinian Territory, Occupied", value: "PS"},
  {key: 169, text: "Panama", value: "PA"},
  {key: 170, text: "Papua New Guinea", value: "PG"},
  {key: 171, text: "Paraguay", value: "PY"},
  {key: 172, text: "Peru", value: "PE"},
  {key: 173, text: "Philippines", value: "PH"},
  {key: 174, text: "Pitcairn", value: "PN"},
  {key: 175, text: "Poland", value: "PL"},
  {key: 176, text: "Portugal", value: "PT"},
  {key: 177, text: "Puerto Rico", value: "PR"},
  {key: 178, text: "Qatar", value: "QA", lat: 0, lng:0},
  {key: 179, text: "Reunion", value: "RE"},
  {key: 180, text: "Romania", value: "RO"},
  {key: 181, text: "Russian Federation", value: "RU", lat: 0, lng:0},
  {key: 182, text: "RWANDA", value: "RW"},
  {key: 183, text: "Saint Helena", value: "SH"},
  {key: 184, text: "Saint Kitts and Nevis", value: "KN"},
  {key: 185, text: "Saint Lucia", value: "LC"},
  {key: 186, text: "Saint Pierre and Miquelon", value: "PM"},
  {key: 187, text: "Saint Vincent and the Grenadines", value: "VC"},
  {key: 188, text: "Samoa", value: "WS"},
  {key: 189, text: "San Marino", value: "SM"},
  {key: 190, text: "Sao Tome and Principe", value: "ST"},
  {key: 191, text: "Saudi Arabia", value: "SA", lat: 0, lng:0},
  {key: 192, text: "Senegal", value: "SN"},
  {key: 193, text: "Serbia and Montenegro", value: "CS"},
  {key: 194, text: "Seychelles", value: "SC"},
  {key: 195, text: "Sierra Leone", value: "SL"},
  {key: 196, text: "Singapore", value: "SG"},
  {key: 197, text: "Slovakia", value: "SK"},
  {key: 198, text: "Slovenia", value: "SI"},
  {key: 199, text: "Solomon Islands", value: "SB"},
  {key: 200, text: "Somalia", value: "SO"},
  {key: 201, text: "South Africa", value: "ZA"},
  {key: 202, text: "South Georgia and the South Sandwich Islands", value: "GS"},
  {key: 203, text: "Spain", value: "ES"},
  {key: 204, text: "Sri Lanka", value: "LK"},
  {key: 205, text: "Sudan", value: "SD"},
  {key: 206, text: "Suriname", value: "SR"},
  {key: 207, text: "Svalbard and Jan Mayen", value: "SJ"},
  {key: 208, text: "Swaziland", value: "SZ"},
  {key: 209, text: "Sweden", value: "SE"},
  {key: 210, text: "Switzerland", value: "CH"},
  {key: 211, text: "Syrian Arab Republic", value: "SY"},
  {key: 212, text: "Taiwan, Province of China", value: "TW", lat: 0, lng:0},
  {key: 213, text: "Tajikistan", value: "TJ"},
  {key: 214, text: "Tanzania, United Republic of", value: "TZ"},
  {key: 215, text: "Thailand", value: "TH"},
  {key: 216, text: "Timor-Leste", value: "TL"},
  {key: 217, text: "Togo", value: "TG"},
  {key: 218, text: "Tokelau", value: "TK"},
  {key: 219, text: "Tonga", value: "TO"},
  {key: 220, text: "Trinidad and Tobago", value: "TT"},
  {key: 221, text: "Tunisia", value: "TN", lat: 34, lng:9},
  {key: 222, text: "Turkey", value: "TR"},
  {key: 223, text: "Turkmenistan", value: "TM"},
  {key: 224, text: "Turks and Caicos Islands", value: "TC"},
  {key: 225, text: "Tuvalu", value: "TV"},
  {key: 226, text: "Uganda", value: "UG"},
  {key: 227, text: "Ukraine", value: "UA"},
  {key: 228, text: "United Arab Emirates", value: "AE", lat: 0, lng:0},
  {key: 229, text: "United Kingdom", value: "GB", lat: 0, lng:0},
  {key: 230, text: "United States", value: "US", lat: 0, lng:0},
  {key: 231, text: "United States Minor Outlying Islands", value: "UM"},
  {key: 232, text: "Uruguay", value: "UY"},
  {key: 233, text: "Uzbekistan", value: "UZ"},
  {key: 234, text: "Vanuatu", value: "VU"},
  {key: 235, text: "Venezuela", value: "VE"},
  {key: 236, text: "Viet Nam", value: "VN"},
  {key: 237, text: "Virgin Islands, British", value: "VG"},
  {key: 238, text: "Virgin Islands, U.S.", value: "VI"},
  {key: 239, text: "Wallis and Futuna", value: "WF"},
  {key: 240, text: "Western Sahara", value: "EH"},
  {key: 241, text: "Yemen", value: "YE"},
  {key: 242, text: "Zambia", value: "ZM"},
  {key: 243, text: "Zimbabwe", value: "ZW"}
]
export default WorldMapList 