const ItaliaTrans = {
    translation : {
        loginPage : {
            inputIdentifiantText : '',
            inputPasswordText : '',
            btnLogInText : '',
            inscrireLinkText : '',
            inscrireisFreeText : '',
            pleaseInscrireOneText : '',
            pleaseInscrireTwoText : '',
            toastText:{
                failOne:'',
                failTwo:'',
            },
        },
        signUpPage : {
            infoGenerale :{
                nomEtPrenomText : '',
                naissanceText : '',
                selectGenreText : '',
                maleGenreText : '',
                femaleGenreText : '',
                phoneText : '',
            },
            locationGeo :{
                positionGeoText : '',
                selectGouvText : '',
                selectDelegText : '', 
            },
            selectPhoto :{
                selectPhotoText : '',
            },
            inputPasssword :{
                inputPassswordText : '',
                inputIdentifText : '',
                inputPWDText : '', 
                repeatPWDText : '', 
                repeatPWDPlaceholderText : '', 
            },
            nextBtnText:'',
            finishBtnText:'',
            toastText:{
                failOne:'',
                failTwo:'',
            },
        },
        mainPage: {
            mainTitle: 'Piattaforma Abyedh',
            mainsubads: 'Molte applicazioni in un\'unica app',
            mainlogInText: 'Accedi',
            mainsearchInput: '... Ricerca',
            qrCodeScan: '... Scansione',
            dropDownProfile: {
                profile: 'Profilo',
                systemLogIn: 'Accesso al sistema',
                systemSuivie: 'Seguimento della registrazione',
                parametres: 'Cambia lingua',
                logOut: 'Esci',
            },
            qrCodeScanner: {
                scanText: 'Scansiona l\'identificatore',
                voirProfileText: 'Visualizza profilo'
            },
            toolsModalText: 'App bianche',
            voisTous: 'Mostra tutto',            
            mainadsCard: 'La Piattaforma Abyedh ti aiuta in diversi settori a trovare: ',

            mainTagsName: {
                sante: 'Salute',
                education: 'Educazione',
                transportation: 'Trasporti',
                nutrition: 'Nutrizione',
                houseCar: 'Casa e Auto',
                construction: 'Costruzione',
                life: 'Vita e Bellezza',
                culture: 'Cultura e Gioventù',
                politique: 'Politica e Diritti',
                finance: 'Finanza',
                agricole: 'Agricoltura',
                tourism: 'Turismo',
                generale: 'Servizi Generali',
                commerceSmall: 'Commercio al Dettaglio',
                transportationSmall: 'Trasporti',
                lifeSmall: 'Vita e Bellezza',
                restaurationSmall: 'Ospitalità e Viaggi',
                constructionSmall: 'Costruzione',
                artisanatSmall: 'Servizi Artigianali',
                financeSmall: 'Finanza e Business',
             },
            itemsNames: {
                docteur: 'Dottore',
                infirmier: 'Infermiere',
                pharmacie: 'Farmacia',
                labo: 'Laboratorio',
                clinique: 'Clinica',
                centreMD: 'Centro Medico',
                admin_s_hospital: 'Amministrazione Ospedaliera',
                admin_s_csb: 'Amministrazione CSB',
                garderie: 'Asilo Nido',
                ecole: 'Scuola',
                lycee: 'Liceo',
                universite: 'Università',
                formation: 'Formazione',
                librairie: 'Libreria',
                admin_e_centre: 'Amministrazione Centro',
                admin_e_biblio: 'Amministrazione Biblioteca',
                admin_e_ecole: 'Amministrazione Scuola',
                admin_e_lycee: 'Amministrazione Liceo',
                admin_e_universite: 'Amministrazione Università',
                admin_e_ss: 'Amministrazione Servizi',
                transporteur: 'Azienda di Trasporti',
                autoecole: 'Autoscuola',
                location: 'Noleggio Auto',
                qiosque: 'Edicola',
                parking: 'Parcheggio',
                mecanicien: 'Meccanico',
                Taxi: 'Taxi',
                Louage: 'Servizio di Taxi Collettivo',
                magazin: 'Negozio',
                boulengerie: 'Panetteria',
                fruiterie: 'Fruttivendolo',
                patesserie: 'Pasticceria',
                epicerie: 'Alimentari',
                electromenager: 'Negozio di Elettrodomestici',
                meublerie: 'Negozio di Mobili',
                boutique: 'Boutique',
                bijouterie: 'Gioielleria',
                quincaillerie: 'Ferramenta',
                depot: 'Deposito',
                boucherie: 'Macelleria',
                cafe: 'Caffetteria',
                restaurant: 'Ristorante',
                hotels: 'Hotel',
                vg_agence: 'Agenzia di Viaggi',
                coiffure: 'Salone di Bellezza',
                salle_marriage: 'Sala per Matrimoni',
                fourniture_marriage: 'Articoli per Matrimoni',
                orchestre: 'Orchestra',
                chef: 'Chef',
                photographe: 'Fotografo',
                forgeron: 'Fabbro',
                menuisier: 'Falegname',
                peinture: 'Pittura',
                electricien: 'Elettricista',
                plombier: 'Idraulico',
                cristalerie: 'Vetreria',
                marbrerie: 'Marmista',
                courtier: 'Agente',
                contracteur: 'Appaltatore',
                architecture: 'Architettura',
                gym: 'Palestra',
                pyscine: 'Piscina',
                stade: 'Stadio',
                cinema: 'Cinema',
                theatre: 'Teatro',
                musee: 'Museo',
                admin_c_mj: 'Centro Giovanile',
                admin_c_mc: 'Centro Culturale',
                admin_c_me: 'Club per Bambini',
                comptable: 'Contabile',
                bank: 'Banca',
                admin_f_poste: 'Ufficio Postale',
                admin_f_rf: 'Ufficio Finanziario',
                avocat: 'Avvocato',
                admin_a_mu: 'Amministrazione Comunale',
                admin_a_police: 'Stazione di Polizia',
                admin_a_ar: 'Amministrazione/Rappresentanza',
                admin_a_court: 'Tribunale',
                admin_a_mosq: 'Moschea',
                ambassade: 'Ambasciata',
                fourragerie: 'Negozio di Alimenti per Animali',
                veterinaire: 'Veterinario',
                agritools: 'Attrezzatura Agricola',
             },
            googlePlayAds: 'Scarica l\'app di Abyedh per un\'esperienza utente migliore',
            googlePlayAdsAppVolume: 'Dimensione: 2,85 MB | Ultimo aggiornamento 25/11/2023',
            toolsTitls: 'Strumenti Abyedh',
            toolsSSText: 'Scopri una serie di strumenti Abyedh per la tua vita quotidiana',
            toolsBSText1: 'Abyedh offre una serie di strumenti per creare un ambiente informativo che aiuti tutti gli utenti nelle loro attività quotidiane',
            toolsBSText2: 'Scopri gli strumenti Abyedh qui',
            footerVision: 'La visione di Abyedh',
            footerHow: 'Cambia il paese',
            footerWho: 'Chi siamo?',
            googlePlayAds1: '',
            googlePlayAds2: '',
            googlePlayAds3: '',
            googlePlayAds4: '',
            toastText:{
                failOne:'',
                failTwo:'',
            },
        },
        landingPage: {
            selectText:'',
            itemsToSelect : {
                docteur : {
                    one:'',
                    two:'',
                    three:'',
                },
                infirmier :{
                    one:'',
                    two:'',
                    three:'',
                },
                pharmacie :{
                    one:'',
                    two:'',
                    three:'',
                },
                labo :{
                    one:'',
                    two:'',
                    three:'',
                },
                clinique :{
                    one:'',
                    two:'',
                    three:'',
                },
                centreMD :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_s_hospital :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_s_csb :{
                    one:'',
                    two:'',
                    three:'',
                },
                garderie :{
                    one:'',
                    two:'',
                    three:'',
                },
                ecole :{
                    one:'',
                    two:'',
                    three:'',
                },
                lycee :{
                    one:'',
                    two:'',
                    three:'',
                },
                universite :{
                    one:'',
                    two:'',
                    three:'',
                },
                formation :{
                    one:'',
                    two:'',
                    three:'',
                },
                librairie :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_e_centre :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_e_biblio :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_e_ecole :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_e_lycee :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_e_universite :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_e_ss :{
                    one:'',
                    two:'',
                    three:'',
                },
                transporteur :{
                    one:'',
                    two:'',
                    three:'',
                },
                autoecole :{
                    one:'',
                    two:'',
                    three:'',
                },
                location :{
                    one:'',
                    two:'',
                    three:'',
                },
                qiosque :{
                    one:'',
                    two:'',
                    three:'',
                },
                parking :{
                    one:'',
                    two:'',
                    three:'',
                },
                mecanicien :{
                    one:'',
                    two:'',
                    three:'',
                },
                Taxi :{
                    one:'',
                    two:'',
                    three:'',
                },
                Louage :{
                    one:'',
                    two:'',
                    three:'',
                },
                magazin :{
                    one:'',
                    two:'',
                    three:'',
                },
                boulengerie :{
                    one:'',
                    two:'',
                    three:'',
                },
                fruiterie :{
                    one:'',
                    two:'',
                    three:'',
                },
                patesserie :{
                    one:'',
                    two:'',
                    three:'',
                },
                epicerie :{
                    one:'',
                    two:'',
                    three:'',
                },
                electromenager :{
                    one:'',
                    two:'',
                    three:'',
                },
                meublerie :{
                    one:'',
                    two:'',
                    three:'',
                },
                boutique :{
                    one:'',
                    two:'',
                    three:'',
                },
                bijouterie :{
                    one:'',
                    two:'',
                    three:'',
                },
                quincaillerie :{
                    one:'',
                    two:'',
                    three:'',
                },
                depot :{
                    one:'',
                    two:'',
                    three:'',
                },
                boucherie :{
                    one:'',
                    two:'',
                    three:'',
                },
                cafe :{
                    one:'',
                    two:'',
                    three:'',
                },
                restaurant :{
                    one:'',
                    two:'',
                    three:'',
                },
                hotels :{
                    one:'',
                    two:'',
                    three:'',
                },
                vg_agence :{
                    one:'',
                    two:'',
                    three:'',
                },
                coiffure :{
                    one:'',
                    two:'',
                    three:'',
                },
                salle_marriage :{
                    one:'',
                    two:'',
                    three:'',
                },
                fourniture_marriage :{
                    one:'',
                    two:'',
                    three:'',
                },
                orchestre :{
                    one:'',
                    two:'',
                    three:'',
                },
                chef :{
                    one:'',
                    two:'',
                    three:'',
                },
                photographe :{
                    one:'',
                    two:'',
                    three:'',
                },
                forgeron :{
                    one:'',
                    two:'',
                    three:'',
                },
                menuisier :{
                    one:'',
                    two:'',
                    three:'',
                },
                peinture :{
                    one:'',
                    two:'',
                    three:'',
                },
                electricien :{
                    one:'',
                    two:'',
                    three:'',
                },
                plombier :{
                    one:'',
                    two:'',
                    three:'',
                },
                cristalerie :{
                    one:'',
                    two:'',
                    three:'',
                },
                marbrerie :{
                    one:'',
                    two:'',
                    three:'',
                },
                courtier :{
                    one:'',
                    two:'',
                    three:'',
                },
                contracteur :{
                    one:'',
                    two:'',
                    three:'',
                },
                architecture :{
                    one:'',
                    two:'',
                    three:'',
                },
                gym :{
                    one:'',
                    two:'',
                    three:'',
                },
                pyscine :{
                    one:'',
                    two:'',
                    three:'',
                },
                stade :{
                    one:'',
                    two:'',
                    three:'',
                },
                cinema :{
                    one:'',
                    two:'',
                    three:'',
                },
                theatre :{
                    one:'',
                    two:'',
                    three:'',
                },
                musee :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_c_mj :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_c_mc :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_c_me :{
                    one:'',
                    two:'',
                    three:'',
                },
                comptable :{
                    one:'',
                    two:'',
                    three:'',
                },
                bank :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_f_poste :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_f_rf :{
                    one:'',
                    two:'',
                    three:'',
                },
                avocat :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_a_mu :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_a_police :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_a_ar :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_a_court :{
                    one:'',
                    two:'',
                    three:'',
                },
                admin_a_mosq :{
                    one:'',
                    two:'',
                    three:'',
                },
                ambassade :{
                    one:'',
                    two:'',
                    three:'',
                },
                fourragerie :{
                    one:'',
                    two:'',
                    three:'',
                },
                veterinaire :{
                    one:'',
                    two:'',
                    three:'',
                },
                agritools :{
                    one:'',
                    two:'',
                    three:'',
                },
                
            },
            movingAdsText : {
                docteur : {
                    one:'',
                    two:'',
                },
                infirmier :{
                    one:'',
                    two:'',
                },
                pharmacie :{
                    one:'',
                    two:'',
                },
                labo :{
                    one:'',
                    two:'',
                },
                clinique :{
                    one:'',
                    two:'',
                },
                centreMD :{
                    one:'',
                    two:'',
                },
                admin_s_hospital :{
                    one:'',
                    two:'',
                },
                admin_s_csb :{
                    one:'',
                    two:'',
                },
                garderie :{
                    one:'',
                    two:'',
                },
                ecole :{
                    one:'',
                    two:'',
                },
                lycee :{
                    one:'',
                    two:'',
                },
                universite :{
                    one:'',
                    two:'',
                },
                formation :{
                    one:'',
                    two:'',
                },
                librairie :{
                    one:'',
                    two:'',
                },
                admin_e_centre :{
                    one:'',
                    two:'',
                },
                admin_e_biblio :{
                    one:'',
                    two:'',
                },
                admin_e_ecole :{
                    one:'',
                    two:'',
                },
                admin_e_lycee :{
                    one:'',
                    two:'',
                },
                admin_e_universite :{
                    one:'',
                    two:'',
                },
                admin_e_ss :{
                    one:'',
                    two:'',
                },
                transporteur :{
                    one:'',
                    two:'',
                },
                autoecole :{
                    one:'',
                    two:'',
                },
                location :{
                    one:'',
                    two:'',
                },
                qiosque :{
                    one:'',
                    two:'',
                },
                parking :{
                    one:'',
                    two:'',
                },
                mecanicien :{
                    one:'',
                    two:'',
                },
                Taxi :{
                    one:'',
                    two:'',
                },
                Louage :{
                    one:'',
                    two:'',
                },
                magazin :{
                    one:'',
                    two:'',
                },
                boulengerie :{
                    one:'',
                    two:'',
                },
                fruiterie :{
                    one:'',
                    two:'',
                },
                patesserie :{
                    one:'',
                    two:'',
                },
                epicerie :{
                    one:'',
                    two:'',
                },
                electromenager :{
                    one:'',
                    two:'',
                },
                meublerie :{
                    one:'',
                    two:'',
                },
                boutique :{
                    one:'',
                    two:'',
                },
                bijouterie :{
                    one:'',
                    two:'',
                },
                quincaillerie :{
                    one:'',
                    two:'',
                },
                depot :{
                    one:'',
                    two:'',
                },
                boucherie :{
                    one:'',
                    two:'',
                },
                cafe :{
                    one:'',
                    two:'',
                },
                restaurant :{
                    one:'',
                    two:'',
                },
                hotels :{
                    one:'',
                    two:'',
                },
                vg_agence :{
                    one:'',
                    two:'',
                },
                coiffure :{
                    one:'',
                    two:'',
                },
                salle_marriage :{
                    one:'',
                    two:'',
                },
                fourniture_marriage :{
                    one:'',
                    two:'',
                },
                orchestre :{
                    one:'',
                    two:'',
                },
                chef :{
                    one:'',
                    two:'',
                },
                photographe :{
                    one:'',
                    two:'',
                },
                forgeron :{
                    one:'',
                    two:'',
                },
                menuisier :{
                    one:'',
                    two:'',
                },
                peinture :{
                    one:'',
                    two:'',
                },
                electricien :{
                    one:'',
                    two:'',
                },
                plombier :{
                    one:'',
                    two:'',
                },
                cristalerie :{
                    one:'',
                    two:'',
                },
                marbrerie :{
                    one:'',
                    two:'',
                },
                courtier :{
                    one:'',
                    two:'',
                },
                contracteur :{
                    one:'',
                    two:'',
                },
                architecture :{
                    one:'',
                    two:'',
                },
                gym :{
                    one:'',
                    two:'',
                },
                pyscine :{
                    one:'',
                    two:'',
                },
                stade :{
                    one:'',
                    two:'',
                },
                cinema :{
                    one:'',
                    two:'',
                },
                theatre :{
                    one:'',
                    two:'',
                },
                musee :{
                    one:'',
                    two:'',
                },
                admin_c_mj :{
                    one:'',
                    two:'',
                },
                admin_c_mc :{
                    one:'',
                    two:'',
                },
                admin_c_me :{
                    one:'',
                    two:'',
                },
                comptable :{
                    one:'',
                    two:'',
                },
                bank :{
                    one:'',
                    two:'',
                },
                admin_f_poste :{
                    one:'',
                    two:'',
                },
                admin_f_rf :{
                    one:'',
                    two:'',
                },
                avocat :{
                    one:'',
                    two:'',
                },
                admin_a_mu :{
                    one:'',
                    two:'',
                },
                admin_a_police :{
                    one:'',
                    two:'',
                },
                admin_a_ar :{
                    one:'',
                    two:'',
                },
                admin_a_court :{
                    one:'',
                    two:'',
                },
                admin_a_mosq :{
                    one:'',
                    two:'',
                },
                ambassade :{
                    one:'',
                    two:'',
                },
                fourragerie :{
                    one:'',
                    two:'',
                },
                veterinaire :{
                    one:'',
                    two:'',
                },
                agritools :{
                    one:'',
                    two:'',
                },
                
            },
            fastSearchText:'' ,
            selectGouvText:'' ,
            selectDelegText:'' ,
            rechercheBtnText:'' ,

            titleSystemAds:'' ,
            textSystemAds:'' ,
            systemNames : {
                docteur : 'طبيب',
                infirmier :'مَمَرٍّضْ',
                pharmacie :'صَيْدَلِية',
                labo :'مَخْبَرْ تَحَالِيلْ',
                clinique :'مِصَحَّة',
                centreMD :'مَرْكَزْ عِلاَجْ',
                admin_s_hospital :'مٌسْتَشْفَي',
                admin_s_csb :'مٌسْتَشْفَي',
                garderie :'مَرْكِزْ صِحَّة',
                ecole :'مَرْكِزْ صِحَّة',
                lycee :'مَرْكِزْ صِحَّة',
                universite :'مَرْكِزْ صِحَّة',
                formation :'مَرْكِزْ صِحَّة',
                librairie :'مَرْكِزْ صِحَّة',
                admin_e_centre :'مَرْكِزْ صِحَّة',
                admin_e_biblio :'مَرْكِزْ صِحَّة',
                admin_e_ecole :'مَرْكِزْ صِحَّة',
                admin_e_lycee :'مَرْكِزْ صِحَّة',
                admin_e_universite :'مَرْكِزْ صِحَّة',
                admin_e_ss :'مَرْكِزْ صِحَّة',
                transporteur :'مَرْكِزْ صِحَّة',
                autoecole :'مَرْكِزْ صِحَّة',
                location :'مَرْكِزْ صِحَّة',
                qiosque :'مَرْكِزْ صِحَّة',
                parking :'مَرْكِزْ صِحَّة',
                mecanicien :'مَرْكِزْ صِحَّة',
                Taxi :'مَرْكِزْ صِحَّة',
                Louage :'مَرْكِزْ صِحَّة',
                magazin :'مَرْكِزْ صِحَّة',
                boulengerie :'مَرْكِزْ صِحَّة',
                fruiterie :'مَرْكِزْ صِحَّة',
                patesserie :'مَرْكِزْ صِحَّة',
                epicerie :'مَرْكِزْ صِحَّة',
                electromenager :'مَرْكِزْ صِحَّة',
                meublerie :'مَرْكِزْ صِحَّة',
                boutique :'مَرْكِزْ صِحَّة',
                bijouterie :'مَرْكِزْ صِحَّة',
                quincaillerie :'مَرْكِزْ صِحَّة',
                depot :'مَرْكِزْ صِحَّة',
                boucherie :'مَرْكِزْ صِحَّة',
                cafe :'مَرْكِزْ صِحَّة',
                restaurant :'مَرْكِزْ صِحَّة',
                hotels :'مَرْكِزْ صِحَّة',
                vg_agence :'مَرْكِزْ صِحَّة',
                coiffure :'مَرْكِزْ صِحَّة',
                salle_marriage :'مَرْكِزْ صِحَّة',
                fourniture_marriage :'مَرْكِزْ صِحَّة',
                orchestre :'مَرْكِزْ صِحَّة',
                chef :'مَرْكِزْ صِحَّة',
                photographe :'مَرْكِزْ صِحَّة',
                forgeron :'مَرْكِزْ صِحَّة',
                menuisier :'مَرْكِزْ صِحَّة',
                peinture :'مَرْكِزْ صِحَّة',
                electricien :'مَرْكِزْ صِحَّة',
                plombier :'مَرْكِزْ صِحَّة',
                cristalerie :'مَرْكِزْ صِحَّة',
                marbrerie :'مَرْكِزْ صِحَّة',
                courtier :'مَرْكِزْ صِحَّة',
                contracteur :'مَرْكِزْ صِحَّة',
                architecture :'مَرْكِزْ صِحَّة',
                gym :'مَرْكِزْ صِحَّة',
                pyscine :'مَرْكِزْ صِحَّة',
                stade :'مَرْكِزْ صِحَّة',
                cinema :'مَرْكِزْ صِحَّة',
                theatre :'مَرْكِزْ صِحَّة',
                musee :'مَرْكِزْ صِحَّة',
                admin_c_mj :'مَرْكِزْ صِحَّة',
                admin_c_mc :'مَرْكِزْ صِحَّة',
                admin_c_me :'مَرْكِزْ صِحَّة',
                comptable :'مَرْكِزْ صِحَّة',
                bank :'مَرْكِزْ صِحَّة',
                admin_f_poste :'مَرْكِزْ صِحَّة',
                admin_f_rf :'مَرْكِزْ صِحَّة',
                avocat :'مَرْكِزْ صِحَّة',
                admin_a_mu :'مَرْكِزْ صِحَّة',
                admin_a_police :'مَرْكِزْ صِحَّة',
                admin_a_ar :'مَرْكِزْ صِحَّة',
                admin_a_court :'مَرْكِزْ صِحَّة',
                admin_a_mosq :'مَرْكِزْ صِحَّة',
                ambassade :'مَرْكِزْ صِحَّة',
                fourragerie :'مَرْكِزْ صِحَّة',
                veterinaire :'مَرْكِزْ صِحَّة',
                agritools :'مَرْكِزْ صِحَّة',
                
            },
            goToSystemBtnText:'' ,
            SubscribeBtnText:'' ,
            suggestionAndFavText:'' ,
            toastText:{
                failOne:'',
                failTwo:'',
            },
        },
        resultPage : {
            actionTextName : {
                docteur : 'طبيب',
                infirmier :'مَمَرٍّضْ',
                pharmacie :'صَيْدَلِية',
                labo :'مَخْبَرْ تَحَالِيلْ',
                clinique :'مِصَحَّة',
                centreMD :'مَرْكَزْ عِلاَجْ',
                admin_s_hospital :'مٌسْتَشْفَي',
                admin_s_csb :'مٌسْتَشْفَي',
                garderie :'مَرْكِزْ صِحَّة',
                ecole :'مَرْكِزْ صِحَّة',
                lycee :'مَرْكِزْ صِحَّة',
                universite :'مَرْكِزْ صِحَّة',
                formation :'مَرْكِزْ صِحَّة',
                librairie :'مَرْكِزْ صِحَّة',
                admin_e_centre :'مَرْكِزْ صِحَّة',
                admin_e_biblio :'مَرْكِزْ صِحَّة',
                admin_e_ecole :'مَرْكِزْ صِحَّة',
                admin_e_lycee :'مَرْكِزْ صِحَّة',
                admin_e_universite :'مَرْكِزْ صِحَّة',
                admin_e_ss :'مَرْكِزْ صِحَّة',
                transporteur :'مَرْكِزْ صِحَّة',
                autoecole :'مَرْكِزْ صِحَّة',
                location :'مَرْكِزْ صِحَّة',
                qiosque :'مَرْكِزْ صِحَّة',
                parking :'مَرْكِزْ صِحَّة',
                mecanicien :'مَرْكِزْ صِحَّة',
                Taxi :'مَرْكِزْ صِحَّة',
                Louage :'مَرْكِزْ صِحَّة',
                magazin :'مَرْكِزْ صِحَّة',
                boulengerie :'مَرْكِزْ صِحَّة',
                fruiterie :'مَرْكِزْ صِحَّة',
                patesserie :'مَرْكِزْ صِحَّة',
                epicerie :'مَرْكِزْ صِحَّة',
                electromenager :'مَرْكِزْ صِحَّة',
                meublerie :'مَرْكِزْ صِحَّة',
                boutique :'مَرْكِزْ صِحَّة',
                bijouterie :'مَرْكِزْ صِحَّة',
                quincaillerie :'مَرْكِزْ صِحَّة',
                depot :'مَرْكِزْ صِحَّة',
                boucherie :'مَرْكِزْ صِحَّة',
                cafe :'مَرْكِزْ صِحَّة',
                restaurant :'مَرْكِزْ صِحَّة',
                hotels :'مَرْكِزْ صِحَّة',
                vg_agence :'مَرْكِزْ صِحَّة',
                coiffure :'مَرْكِزْ صِحَّة',
                salle_marriage :'مَرْكِزْ صِحَّة',
                fourniture_marriage :'مَرْكِزْ صِحَّة',
                orchestre :'مَرْكِزْ صِحَّة',
                chef :'مَرْكِزْ صِحَّة',
                photographe :'مَرْكِزْ صِحَّة',
                forgeron :'مَرْكِزْ صِحَّة',
                menuisier :'مَرْكِزْ صِحَّة',
                peinture :'مَرْكِزْ صِحَّة',
                electricien :'مَرْكِزْ صِحَّة',
                plombier :'مَرْكِزْ صِحَّة',
                cristalerie :'مَرْكِزْ صِحَّة',
                marbrerie :'مَرْكِزْ صِحَّة',
                courtier :'مَرْكِزْ صِحَّة',
                contracteur :'مَرْكِزْ صِحَّة',
                architecture :'مَرْكِزْ صِحَّة',
                gym :'مَرْكِزْ صِحَّة',
                pyscine :'مَرْكِزْ صِحَّة',
                stade :'مَرْكِزْ صِحَّة',
                cinema :'مَرْكِزْ صِحَّة',
                theatre :'مَرْكِزْ صِحَّة',
                musee :'مَرْكِزْ صِحَّة',
                admin_c_mj :'مَرْكِزْ صِحَّة',
                admin_c_mc :'مَرْكِزْ صِحَّة',
                admin_c_me :'مَرْكِزْ صِحَّة',
                comptable :'مَرْكِزْ صِحَّة',
                bank :'مَرْكِزْ صِحَّة',
                admin_f_poste :'مَرْكِزْ صِحَّة',
                admin_f_rf :'مَرْكِزْ صِحَّة',
                avocat :'مَرْكِزْ صِحَّة',
                admin_a_mu :'مَرْكِزْ صِحَّة',
                admin_a_police :'مَرْكِزْ صِحَّة',
                admin_a_ar :'مَرْكِزْ صِحَّة',
                admin_a_court :'مَرْكِزْ صِحَّة',
                admin_a_mosq :'مَرْكِزْ صِحَّة',
                ambassade :'مَرْكِزْ صِحَّة',
                fourragerie :'مَرْكِزْ صِحَّة',
                veterinaire :'مَرْكِزْ صِحَّة',
                agritools :'مَرْكِزْ صِحَّة',
                
            },
            bottomAbyedhText: "abyedh.com",
            toastText:{
                failOne:'',
                failTwo:'',
            },
        },
        profilePage: {
            generalData :{
                generalDataText: '',
                horaireText: '',
                photoesText: '',
                mapText: '',
                avisText: '',
                commentText: '',
                addCommentText: '',
                voirCommentText: '',
                inputCommentPlaceholderText: '',
                saveCommentBtnText: '',
            },
            SpesificData : {
                docteur : {
                    one:'',
                    two:'',
                },
                infirmier :{
                    one:'',
                    two:'',
                },
                pharmacie :{
                    one:'',
                    two:'',
                },
                labo :{
                    one:'',
                    two:'',
                },
                clinique :{
                    one:'',
                    two:'',
                },
                centreMD :{
                    one:'',
                    two:'',
                },
                admin_s_hospital :{
                    one:'',
                    two:'',
                },
                admin_s_csb :{
                    one:'',
                    two:'',
                },
                garderie :{
                    one:'',
                    two:'',
                },
                ecole :{
                    one:'',
                    two:'',
                },
                lycee :{
                    one:'',
                    two:'',
                },
                universite :{
                    one:'',
                    two:'',
                },
                formation :{
                    one:'',
                    two:'',
                },
                librairie :{
                    one:'',
                    two:'',
                },
                admin_e_centre :{
                    one:'',
                    two:'',
                },
                admin_e_biblio :{
                    one:'',
                    two:'',
                },
                admin_e_ecole :{
                    one:'',
                    two:'',
                },
                admin_e_lycee :{
                    one:'',
                    two:'',
                },
                admin_e_universite :{
                    one:'',
                    two:'',
                },
                admin_e_ss :{
                    one:'',
                    two:'',
                },
                transporteur :{
                    one:'',
                    two:'',
                },
                autoecole :{
                    one:'',
                    two:'',
                },
                location :{
                    one:'',
                    two:'',
                },
                qiosque :{
                    one:'',
                    two:'',
                },
                parking :{
                    one:'',
                    two:'',
                },
                mecanicien :{
                    one:'',
                    two:'',
                },
                Taxi :{
                    one:'',
                    two:'',
                },
                Louage :{
                    one:'',
                    two:'',
                },
                magazin :{
                    one:'',
                    two:'',
                },
                boulengerie :{
                    one:'',
                    two:'',
                },
                fruiterie :{
                    one:'',
                    two:'',
                },
                patesserie :{
                    one:'',
                    two:'',
                },
                epicerie :{
                    one:'',
                    two:'',
                },
                electromenager :{
                    one:'',
                    two:'',
                },
                meublerie :{
                    one:'',
                    two:'',
                },
                boutique :{
                    one:'',
                    two:'',
                },
                bijouterie :{
                    one:'',
                    two:'',
                },
                quincaillerie :{
                    one:'',
                    two:'',
                },
                depot :{
                    one:'',
                    two:'',
                },
                boucherie :{
                    one:'',
                    two:'',
                },
                cafe :{
                    one:'',
                    two:'',
                },
                restaurant :{
                    one:'',
                    two:'',
                },
                hotels :{
                    one:'',
                    two:'',
                },
                vg_agence :{
                    one:'',
                    two:'',
                },
                coiffure :{
                    one:'',
                    two:'',
                },
                salle_marriage :{
                    one:'',
                    two:'',
                },
                fourniture_marriage :{
                    one:'',
                    two:'',
                },
                orchestre :{
                    one:'',
                    two:'',
                },
                chef :{
                    one:'',
                    two:'',
                },
                photographe :{
                    one:'',
                    two:'',
                },
                forgeron :{
                    one:'',
                    two:'',
                },
                menuisier :{
                    one:'',
                    two:'',
                },
                peinture :{
                    one:'',
                    two:'',
                },
                electricien :{
                    one:'',
                    two:'',
                },
                plombier :{
                    one:'',
                    two:'',
                },
                cristalerie :{
                    one:'',
                    two:'',
                },
                marbrerie :{
                    one:'',
                    two:'',
                },
                courtier :{
                    one:'',
                    two:'',
                },
                contracteur :{
                    one:'',
                    two:'',
                },
                architecture :{
                    one:'',
                    two:'',
                },
                gym :{
                    one:'',
                    two:'',
                },
                pyscine :{
                    one:'',
                    two:'',
                },
                stade :{
                    one:'',
                    two:'',
                },
                cinema :{
                    one:'',
                    two:'',
                },
                theatre :{
                    one:'',
                    two:'',
                },
                musee :{
                    one:'',
                    two:'',
                },
                admin_c_mj :{
                    one:'',
                    two:'',
                },
                admin_c_mc :{
                    one:'',
                    two:'',
                },
                admin_c_me :{
                    one:'',
                    two:'',
                },
                comptable :{
                    one:'',
                    two:'',
                },
                bank :{
                    one:'',
                    two:'',
                },
                admin_f_poste :{
                    one:'',
                    two:'',
                },
                admin_f_rf :{
                    one:'',
                    two:'',
                },
                avocat :{
                    one:'',
                    two:'',
                },
                admin_a_mu :{
                    one:'',
                    two:'',
                },
                admin_a_police :{
                    one:'',
                    two:'',
                },
                admin_a_ar :{
                    one:'',
                    two:'',
                },
                admin_a_court :{
                    one:'',
                    two:'',
                },
                admin_a_mosq :{
                    one:'',
                    two:'',
                },
                ambassade :{
                    one:'',
                    two:'',
                },
                fourragerie :{
                    one:'',
                    two:'',
                },
                veterinaire :{
                    one:'',
                    two:'',
                },
                agritools :{
                    one:'',
                    two:'',
                },
                
            },
            ActionTabData : {
                docteur : {
                    one:'',
                    two:'',
                },
                infirmier :{
                    one:'',
                    two:'',
                },
                pharmacie :{
                    one:'',
                    two:'',
                },
                labo :{
                    one:'',
                    two:'',
                },
                clinique :{
                    one:'',
                    two:'',
                },
                centreMD :{
                    one:'',
                    two:'',
                },
                admin_s_hospital :{
                    one:'',
                    two:'',
                },
                admin_s_csb :{
                    one:'',
                    two:'',
                },
                garderie :{
                    one:'',
                    two:'',
                },
                ecole :{
                    one:'',
                    two:'',
                },
                lycee :{
                    one:'',
                    two:'',
                },
                universite :{
                    one:'',
                    two:'',
                },
                formation :{
                    one:'',
                    two:'',
                },
                librairie :{
                    one:'',
                    two:'',
                },
                admin_e_centre :{
                    one:'',
                    two:'',
                },
                admin_e_biblio :{
                    one:'',
                    two:'',
                },
                admin_e_ecole :{
                    one:'',
                    two:'',
                },
                admin_e_lycee :{
                    one:'',
                    two:'',
                },
                admin_e_universite :{
                    one:'',
                    two:'',
                },
                admin_e_ss :{
                    one:'',
                    two:'',
                },
                transporteur :{
                    one:'',
                    two:'',
                },
                autoecole :{
                    one:'',
                    two:'',
                },
                location :{
                    one:'',
                    two:'',
                },
                qiosque :{
                    one:'',
                    two:'',
                },
                parking :{
                    one:'',
                    two:'',
                },
                mecanicien :{
                    one:'',
                    two:'',
                },
                Taxi :{
                    one:'',
                    two:'',
                },
                Louage :{
                    one:'',
                    two:'',
                },
                magazin :{
                    one:'',
                    two:'',
                },
                boulengerie :{
                    one:'',
                    two:'',
                },
                fruiterie :{
                    one:'',
                    two:'',
                },
                patesserie :{
                    one:'',
                    two:'',
                },
                epicerie :{
                    one:'',
                    two:'',
                },
                electromenager :{
                    one:'',
                    two:'',
                },
                meublerie :{
                    one:'',
                    two:'',
                },
                boutique :{
                    one:'',
                    two:'',
                },
                bijouterie :{
                    one:'',
                    two:'',
                },
                quincaillerie :{
                    one:'',
                    two:'',
                },
                depot :{
                    one:'',
                    two:'',
                },
                boucherie :{
                    one:'',
                    two:'',
                },
                cafe :{
                    one:'',
                    two:'',
                },
                restaurant :{
                    one:'',
                    two:'',
                },
                hotels :{
                    one:'',
                    two:'',
                },
                vg_agence :{
                    one:'',
                    two:'',
                },
                coiffure :{
                    one:'',
                    two:'',
                },
                salle_marriage :{
                    one:'',
                    two:'',
                },
                fourniture_marriage :{
                    one:'',
                    two:'',
                },
                orchestre :{
                    one:'',
                    two:'',
                },
                chef :{
                    one:'',
                    two:'',
                },
                photographe :{
                    one:'',
                    two:'',
                },
                forgeron :{
                    one:'',
                    two:'',
                },
                menuisier :{
                    one:'',
                    two:'',
                },
                peinture :{
                    one:'',
                    two:'',
                },
                electricien :{
                    one:'',
                    two:'',
                },
                plombier :{
                    one:'',
                    two:'',
                },
                cristalerie :{
                    one:'',
                    two:'',
                },
                marbrerie :{
                    one:'',
                    two:'',
                },
                courtier :{
                    one:'',
                    two:'',
                },
                contracteur :{
                    one:'',
                    two:'',
                },
                architecture :{
                    one:'',
                    two:'',
                },
                gym :{
                    one:'',
                    two:'',
                },
                pyscine :{
                    one:'',
                    two:'',
                },
                stade :{
                    one:'',
                    two:'',
                },
                cinema :{
                    one:'',
                    two:'',
                },
                theatre :{
                    one:'',
                    two:'',
                },
                musee :{
                    one:'',
                    two:'',
                },
                admin_c_mj :{
                    one:'',
                    two:'',
                },
                admin_c_mc :{
                    one:'',
                    two:'',
                },
                admin_c_me :{
                    one:'',
                    two:'',
                },
                comptable :{
                    one:'',
                    two:'',
                },
                bank :{
                    one:'',
                    two:'',
                },
                admin_f_poste :{
                    one:'',
                    two:'',
                },
                admin_f_rf :{
                    one:'',
                    two:'',
                },
                avocat :{
                    one:'',
                    two:'',
                },
                admin_a_mu :{
                    one:'',
                    two:'',
                },
                admin_a_police :{
                    one:'',
                    two:'',
                },
                admin_a_ar :{
                    one:'',
                    two:'',
                },
                admin_a_court :{
                    one:'',
                    two:'',
                },
                admin_a_mosq :{
                    one:'',
                    two:'',
                },
                ambassade :{
                    one:'',
                    two:'',
                },
                fourragerie :{
                    one:'',
                    two:'',
                },
                veterinaire :{
                    one:'',
                    two:'',
                },
                agritools :{
                    one:'',
                    two:'',
                },
                
            },
            FollowTabData : {
                docteur : {
                    one:'',
                    two:'',
                },
                infirmier :{
                    one:'',
                    two:'',
                },
                pharmacie :{
                    one:'',
                    two:'',
                },
                labo :{
                    one:'',
                    two:'',
                },
                clinique :{
                    one:'',
                    two:'',
                },
                centreMD :{
                    one:'',
                    two:'',
                },
                admin_s_hospital :{
                    one:'',
                    two:'',
                },
                admin_s_csb :{
                    one:'',
                    two:'',
                },
                garderie :{
                    one:'',
                    two:'',
                },
                ecole :{
                    one:'',
                    two:'',
                },
                lycee :{
                    one:'',
                    two:'',
                },
                universite :{
                    one:'',
                    two:'',
                },
                formation :{
                    one:'',
                    two:'',
                },
                librairie :{
                    one:'',
                    two:'',
                },
                admin_e_centre :{
                    one:'',
                    two:'',
                },
                admin_e_biblio :{
                    one:'',
                    two:'',
                },
                admin_e_ecole :{
                    one:'',
                    two:'',
                },
                admin_e_lycee :{
                    one:'',
                    two:'',
                },
                admin_e_universite :{
                    one:'',
                    two:'',
                },
                admin_e_ss :{
                    one:'',
                    two:'',
                },
                transporteur :{
                    one:'',
                    two:'',
                },
                autoecole :{
                    one:'',
                    two:'',
                },
                location :{
                    one:'',
                    two:'',
                },
                qiosque :{
                    one:'',
                    two:'',
                },
                parking :{
                    one:'',
                    two:'',
                },
                mecanicien :{
                    one:'',
                    two:'',
                },
                Taxi :{
                    one:'',
                    two:'',
                },
                Louage :{
                    one:'',
                    two:'',
                },
                magazin :{
                    one:'',
                    two:'',
                },
                boulengerie :{
                    one:'',
                    two:'',
                },
                fruiterie :{
                    one:'',
                    two:'',
                },
                patesserie :{
                    one:'',
                    two:'',
                },
                epicerie :{
                    one:'',
                    two:'',
                },
                electromenager :{
                    one:'',
                    two:'',
                },
                meublerie :{
                    one:'',
                    two:'',
                },
                boutique :{
                    one:'',
                    two:'',
                },
                bijouterie :{
                    one:'',
                    two:'',
                },
                quincaillerie :{
                    one:'',
                    two:'',
                },
                depot :{
                    one:'',
                    two:'',
                },
                boucherie :{
                    one:'',
                    two:'',
                },
                cafe :{
                    one:'',
                    two:'',
                },
                restaurant :{
                    one:'',
                    two:'',
                },
                hotels :{
                    one:'',
                    two:'',
                },
                vg_agence :{
                    one:'',
                    two:'',
                },
                coiffure :{
                    one:'',
                    two:'',
                },
                salle_marriage :{
                    one:'',
                    two:'',
                },
                fourniture_marriage :{
                    one:'',
                    two:'',
                },
                orchestre :{
                    one:'',
                    two:'',
                },
                chef :{
                    one:'',
                    two:'',
                },
                photographe :{
                    one:'',
                    two:'',
                },
                forgeron :{
                    one:'',
                    two:'',
                },
                menuisier :{
                    one:'',
                    two:'',
                },
                peinture :{
                    one:'',
                    two:'',
                },
                electricien :{
                    one:'',
                    two:'',
                },
                plombier :{
                    one:'',
                    two:'',
                },
                cristalerie :{
                    one:'',
                    two:'',
                },
                marbrerie :{
                    one:'',
                    two:'',
                },
                courtier :{
                    one:'',
                    two:'',
                },
                contracteur :{
                    one:'',
                    two:'',
                },
                architecture :{
                    one:'',
                    two:'',
                },
                gym :{
                    one:'',
                    two:'',
                },
                pyscine :{
                    one:'',
                    two:'',
                },
                stade :{
                    one:'',
                    two:'',
                },
                cinema :{
                    one:'',
                    two:'',
                },
                theatre :{
                    one:'',
                    two:'',
                },
                musee :{
                    one:'',
                    two:'',
                },
                admin_c_mj :{
                    one:'',
                    two:'',
                },
                admin_c_mc :{
                    one:'',
                    two:'',
                },
                admin_c_me :{
                    one:'',
                    two:'',
                },
                comptable :{
                    one:'',
                    two:'',
                },
                bank :{
                    one:'',
                    two:'',
                },
                admin_f_poste :{
                    one:'',
                    two:'',
                },
                admin_f_rf :{
                    one:'',
                    two:'',
                },
                avocat :{
                    one:'',
                    two:'',
                },
                admin_a_mu :{
                    one:'',
                    two:'',
                },
                admin_a_police :{
                    one:'',
                    two:'',
                },
                admin_a_ar :{
                    one:'',
                    two:'',
                },
                admin_a_court :{
                    one:'',
                    two:'',
                },
                admin_a_mosq :{
                    one:'',
                    two:'',
                },
                ambassade :{
                    one:'',
                    two:'',
                },
                fourragerie :{
                    one:'',
                    two:'',
                },
                veterinaire :{
                    one:'',
                    two:'',
                },
                agritools :{
                    one:'',
                    two:'',
                },
                
            },
            PostsTabs :{
                noPostToShow: '',
                textText: '',
                photoText: '',
                videoText: '',
            },
            toastText:{
                failOne:'',
                failTwo:'',
            },
        },
        userProfile: {
            generalData :{
                generalDataText: '',
                horaireText: '',
                photoesText: '',
                mapText: '',
                avisText: '',
                commentText: '',
                addCommentText: '',
                voirCommentText: '',
                inputCommentPlaceholderText: '',
                saveCommentBtnText: '',
            },
            SpesificData : {
                docteur : {
                    one:'',
                    two:'',
                },
                infirmier :{
                    one:'',
                    two:'',
                },
                pharmacie :{
                    one:'',
                    two:'',
                },
                labo :{
                    one:'',
                    two:'',
                },
                clinique :{
                    one:'',
                    two:'',
                },
                centreMD :{
                    one:'',
                    two:'',
                },
                admin_s_hospital :{
                    one:'',
                    two:'',
                },
                admin_s_csb :{
                    one:'',
                    two:'',
                },
                garderie :{
                    one:'',
                    two:'',
                },
                ecole :{
                    one:'',
                    two:'',
                },
                lycee :{
                    one:'',
                    two:'',
                },
                universite :{
                    one:'',
                    two:'',
                },
                formation :{
                    one:'',
                    two:'',
                },
                librairie :{
                    one:'',
                    two:'',
                },
                admin_e_centre :{
                    one:'',
                    two:'',
                },
                admin_e_biblio :{
                    one:'',
                    two:'',
                },
                admin_e_ecole :{
                    one:'',
                    two:'',
                },
                admin_e_lycee :{
                    one:'',
                    two:'',
                },
                admin_e_universite :{
                    one:'',
                    two:'',
                },
                admin_e_ss :{
                    one:'',
                    two:'',
                },
                transporteur :{
                    one:'',
                    two:'',
                },
                autoecole :{
                    one:'',
                    two:'',
                },
                location :{
                    one:'',
                    two:'',
                },
                qiosque :{
                    one:'',
                    two:'',
                },
                parking :{
                    one:'',
                    two:'',
                },
                mecanicien :{
                    one:'',
                    two:'',
                },
                Taxi :{
                    one:'',
                    two:'',
                },
                Louage :{
                    one:'',
                    two:'',
                },
                magazin :{
                    one:'',
                    two:'',
                },
                boulengerie :{
                    one:'',
                    two:'',
                },
                fruiterie :{
                    one:'',
                    two:'',
                },
                patesserie :{
                    one:'',
                    two:'',
                },
                epicerie :{
                    one:'',
                    two:'',
                },
                electromenager :{
                    one:'',
                    two:'',
                },
                meublerie :{
                    one:'',
                    two:'',
                },
                boutique :{
                    one:'',
                    two:'',
                },
                bijouterie :{
                    one:'',
                    two:'',
                },
                quincaillerie :{
                    one:'',
                    two:'',
                },
                depot :{
                    one:'',
                    two:'',
                },
                boucherie :{
                    one:'',
                    two:'',
                },
                cafe :{
                    one:'',
                    two:'',
                },
                restaurant :{
                    one:'',
                    two:'',
                },
                hotels :{
                    one:'',
                    two:'',
                },
                vg_agence :{
                    one:'',
                    two:'',
                },
                coiffure :{
                    one:'',
                    two:'',
                },
                salle_marriage :{
                    one:'',
                    two:'',
                },
                fourniture_marriage :{
                    one:'',
                    two:'',
                },
                orchestre :{
                    one:'',
                    two:'',
                },
                chef :{
                    one:'',
                    two:'',
                },
                photographe :{
                    one:'',
                    two:'',
                },
                forgeron :{
                    one:'',
                    two:'',
                },
                menuisier :{
                    one:'',
                    two:'',
                },
                peinture :{
                    one:'',
                    two:'',
                },
                electricien :{
                    one:'',
                    two:'',
                },
                plombier :{
                    one:'',
                    two:'',
                },
                cristalerie :{
                    one:'',
                    two:'',
                },
                marbrerie :{
                    one:'',
                    two:'',
                },
                courtier :{
                    one:'',
                    two:'',
                },
                contracteur :{
                    one:'',
                    two:'',
                },
                architecture :{
                    one:'',
                    two:'',
                },
                gym :{
                    one:'',
                    two:'',
                },
                pyscine :{
                    one:'',
                    two:'',
                },
                stade :{
                    one:'',
                    two:'',
                },
                cinema :{
                    one:'',
                    two:'',
                },
                theatre :{
                    one:'',
                    two:'',
                },
                musee :{
                    one:'',
                    two:'',
                },
                admin_c_mj :{
                    one:'',
                    two:'',
                },
                admin_c_mc :{
                    one:'',
                    two:'',
                },
                admin_c_me :{
                    one:'',
                    two:'',
                },
                comptable :{
                    one:'',
                    two:'',
                },
                bank :{
                    one:'',
                    two:'',
                },
                admin_f_poste :{
                    one:'',
                    two:'',
                },
                admin_f_rf :{
                    one:'',
                    two:'',
                },
                avocat :{
                    one:'',
                    two:'',
                },
                admin_a_mu :{
                    one:'',
                    two:'',
                },
                admin_a_police :{
                    one:'',
                    two:'',
                },
                admin_a_ar :{
                    one:'',
                    two:'',
                },
                admin_a_court :{
                    one:'',
                    two:'',
                },
                admin_a_mosq :{
                    one:'',
                    two:'',
                },
                ambassade :{
                    one:'',
                    two:'',
                },
                fourragerie :{
                    one:'',
                    two:'',
                },
                veterinaire :{
                    one:'',
                    two:'',
                },
                agritools :{
                    one:'',
                    two:'',
                },
                
            },
            ActionTabData : {
                docteur : {
                    one:'',
                    two:'',
                },
                infirmier :{
                    one:'',
                    two:'',
                },
                pharmacie :{
                    one:'',
                    two:'',
                },
                labo :{
                    one:'',
                    two:'',
                },
                clinique :{
                    one:'',
                    two:'',
                },
                centreMD :{
                    one:'',
                    two:'',
                },
                admin_s_hospital :{
                    one:'',
                    two:'',
                },
                admin_s_csb :{
                    one:'',
                    two:'',
                },
                garderie :{
                    one:'',
                    two:'',
                },
                ecole :{
                    one:'',
                    two:'',
                },
                lycee :{
                    one:'',
                    two:'',
                },
                universite :{
                    one:'',
                    two:'',
                },
                formation :{
                    one:'',
                    two:'',
                },
                librairie :{
                    one:'',
                    two:'',
                },
                admin_e_centre :{
                    one:'',
                    two:'',
                },
                admin_e_biblio :{
                    one:'',
                    two:'',
                },
                admin_e_ecole :{
                    one:'',
                    two:'',
                },
                admin_e_lycee :{
                    one:'',
                    two:'',
                },
                admin_e_universite :{
                    one:'',
                    two:'',
                },
                admin_e_ss :{
                    one:'',
                    two:'',
                },
                transporteur :{
                    one:'',
                    two:'',
                },
                autoecole :{
                    one:'',
                    two:'',
                },
                location :{
                    one:'',
                    two:'',
                },
                qiosque :{
                    one:'',
                    two:'',
                },
                parking :{
                    one:'',
                    two:'',
                },
                mecanicien :{
                    one:'',
                    two:'',
                },
                Taxi :{
                    one:'',
                    two:'',
                },
                Louage :{
                    one:'',
                    two:'',
                },
                magazin :{
                    one:'',
                    two:'',
                },
                boulengerie :{
                    one:'',
                    two:'',
                },
                fruiterie :{
                    one:'',
                    two:'',
                },
                patesserie :{
                    one:'',
                    two:'',
                },
                epicerie :{
                    one:'',
                    two:'',
                },
                electromenager :{
                    one:'',
                    two:'',
                },
                meublerie :{
                    one:'',
                    two:'',
                },
                boutique :{
                    one:'',
                    two:'',
                },
                bijouterie :{
                    one:'',
                    two:'',
                },
                quincaillerie :{
                    one:'',
                    two:'',
                },
                depot :{
                    one:'',
                    two:'',
                },
                boucherie :{
                    one:'',
                    two:'',
                },
                cafe :{
                    one:'',
                    two:'',
                },
                restaurant :{
                    one:'',
                    two:'',
                },
                hotels :{
                    one:'',
                    two:'',
                },
                vg_agence :{
                    one:'',
                    two:'',
                },
                coiffure :{
                    one:'',
                    two:'',
                },
                salle_marriage :{
                    one:'',
                    two:'',
                },
                fourniture_marriage :{
                    one:'',
                    two:'',
                },
                orchestre :{
                    one:'',
                    two:'',
                },
                chef :{
                    one:'',
                    two:'',
                },
                photographe :{
                    one:'',
                    two:'',
                },
                forgeron :{
                    one:'',
                    two:'',
                },
                menuisier :{
                    one:'',
                    two:'',
                },
                peinture :{
                    one:'',
                    two:'',
                },
                electricien :{
                    one:'',
                    two:'',
                },
                plombier :{
                    one:'',
                    two:'',
                },
                cristalerie :{
                    one:'',
                    two:'',
                },
                marbrerie :{
                    one:'',
                    two:'',
                },
                courtier :{
                    one:'',
                    two:'',
                },
                contracteur :{
                    one:'',
                    two:'',
                },
                architecture :{
                    one:'',
                    two:'',
                },
                gym :{
                    one:'',
                    two:'',
                },
                pyscine :{
                    one:'',
                    two:'',
                },
                stade :{
                    one:'',
                    two:'',
                },
                cinema :{
                    one:'',
                    two:'',
                },
                theatre :{
                    one:'',
                    two:'',
                },
                musee :{
                    one:'',
                    two:'',
                },
                admin_c_mj :{
                    one:'',
                    two:'',
                },
                admin_c_mc :{
                    one:'',
                    two:'',
                },
                admin_c_me :{
                    one:'',
                    two:'',
                },
                comptable :{
                    one:'',
                    two:'',
                },
                bank :{
                    one:'',
                    two:'',
                },
                admin_f_poste :{
                    one:'',
                    two:'',
                },
                admin_f_rf :{
                    one:'',
                    two:'',
                },
                avocat :{
                    one:'',
                    two:'',
                },
                admin_a_mu :{
                    one:'',
                    two:'',
                },
                admin_a_police :{
                    one:'',
                    two:'',
                },
                admin_a_ar :{
                    one:'',
                    two:'',
                },
                admin_a_court :{
                    one:'',
                    two:'',
                },
                admin_a_mosq :{
                    one:'',
                    two:'',
                },
                ambassade :{
                    one:'',
                    two:'',
                },
                fourragerie :{
                    one:'',
                    two:'',
                },
                veterinaire :{
                    one:'',
                    two:'',
                },
                agritools :{
                    one:'',
                    two:'',
                },
                
            },
            FollowTabData : {
                docteur : {
                    one:'',
                    two:'',
                },
                infirmier :{
                    one:'',
                    two:'',
                },
                pharmacie :{
                    one:'',
                    two:'',
                },
                labo :{
                    one:'',
                    two:'',
                },
                clinique :{
                    one:'',
                    two:'',
                },
                centreMD :{
                    one:'',
                    two:'',
                },
                admin_s_hospital :{
                    one:'',
                    two:'',
                },
                admin_s_csb :{
                    one:'',
                    two:'',
                },
                garderie :{
                    one:'',
                    two:'',
                },
                ecole :{
                    one:'',
                    two:'',
                },
                lycee :{
                    one:'',
                    two:'',
                },
                universite :{
                    one:'',
                    two:'',
                },
                formation :{
                    one:'',
                    two:'',
                },
                librairie :{
                    one:'',
                    two:'',
                },
                admin_e_centre :{
                    one:'',
                    two:'',
                },
                admin_e_biblio :{
                    one:'',
                    two:'',
                },
                admin_e_ecole :{
                    one:'',
                    two:'',
                },
                admin_e_lycee :{
                    one:'',
                    two:'',
                },
                admin_e_universite :{
                    one:'',
                    two:'',
                },
                admin_e_ss :{
                    one:'',
                    two:'',
                },
                transporteur :{
                    one:'',
                    two:'',
                },
                autoecole :{
                    one:'',
                    two:'',
                },
                location :{
                    one:'',
                    two:'',
                },
                qiosque :{
                    one:'',
                    two:'',
                },
                parking :{
                    one:'',
                    two:'',
                },
                mecanicien :{
                    one:'',
                    two:'',
                },
                Taxi :{
                    one:'',
                    two:'',
                },
                Louage :{
                    one:'',
                    two:'',
                },
                magazin :{
                    one:'',
                    two:'',
                },
                boulengerie :{
                    one:'',
                    two:'',
                },
                fruiterie :{
                    one:'',
                    two:'',
                },
                patesserie :{
                    one:'',
                    two:'',
                },
                epicerie :{
                    one:'',
                    two:'',
                },
                electromenager :{
                    one:'',
                    two:'',
                },
                meublerie :{
                    one:'',
                    two:'',
                },
                boutique :{
                    one:'',
                    two:'',
                },
                bijouterie :{
                    one:'',
                    two:'',
                },
                quincaillerie :{
                    one:'',
                    two:'',
                },
                depot :{
                    one:'',
                    two:'',
                },
                boucherie :{
                    one:'',
                    two:'',
                },
                cafe :{
                    one:'',
                    two:'',
                },
                restaurant :{
                    one:'',
                    two:'',
                },
                hotels :{
                    one:'',
                    two:'',
                },
                vg_agence :{
                    one:'',
                    two:'',
                },
                coiffure :{
                    one:'',
                    two:'',
                },
                salle_marriage :{
                    one:'',
                    two:'',
                },
                fourniture_marriage :{
                    one:'',
                    two:'',
                },
                orchestre :{
                    one:'',
                    two:'',
                },
                chef :{
                    one:'',
                    two:'',
                },
                photographe :{
                    one:'',
                    two:'',
                },
                forgeron :{
                    one:'',
                    two:'',
                },
                menuisier :{
                    one:'',
                    two:'',
                },
                peinture :{
                    one:'',
                    two:'',
                },
                electricien :{
                    one:'',
                    two:'',
                },
                plombier :{
                    one:'',
                    two:'',
                },
                cristalerie :{
                    one:'',
                    two:'',
                },
                marbrerie :{
                    one:'',
                    two:'',
                },
                courtier :{
                    one:'',
                    two:'',
                },
                contracteur :{
                    one:'',
                    two:'',
                },
                architecture :{
                    one:'',
                    two:'',
                },
                gym :{
                    one:'',
                    two:'',
                },
                pyscine :{
                    one:'',
                    two:'',
                },
                stade :{
                    one:'',
                    two:'',
                },
                cinema :{
                    one:'',
                    two:'',
                },
                theatre :{
                    one:'',
                    two:'',
                },
                musee :{
                    one:'',
                    two:'',
                },
                admin_c_mj :{
                    one:'',
                    two:'',
                },
                admin_c_mc :{
                    one:'',
                    two:'',
                },
                admin_c_me :{
                    one:'',
                    two:'',
                },
                comptable :{
                    one:'',
                    two:'',
                },
                bank :{
                    one:'',
                    two:'',
                },
                admin_f_poste :{
                    one:'',
                    two:'',
                },
                admin_f_rf :{
                    one:'',
                    two:'',
                },
                avocat :{
                    one:'',
                    two:'',
                },
                admin_a_mu :{
                    one:'',
                    two:'',
                },
                admin_a_police :{
                    one:'',
                    two:'',
                },
                admin_a_ar :{
                    one:'',
                    two:'',
                },
                admin_a_court :{
                    one:'',
                    two:'',
                },
                admin_a_mosq :{
                    one:'',
                    two:'',
                },
                ambassade :{
                    one:'',
                    two:'',
                },
                fourragerie :{
                    one:'',
                    two:'',
                },
                veterinaire :{
                    one:'',
                    two:'',
                },
                agritools :{
                    one:'',
                    two:'',
                },
                
            },
            PostsTabs :{
                noPostToShow: '',
                textText: '',
                photoText: '',
                videoText: '',
            },
            toastText:{
                failOne:'',
                failTwo:'',
            },
        },
        subscribeToSystems : {
            shouldLogInData: {
                shouldText: "Devi prima effettuare il login",
                detailText: "Per abbonarti a {{one}}, devi prima effettuare il login alla piattaforma, poi tornare su questa pagina per completare il processo di abbonamento.",
                clicLink: "Accedi"
              }              
        },
        appPages : {
            mainPage : {
                publicationText: 'Pubblicazioni',
                horaireText: 'Orari',
                imagesText: 'Immagini',
                ratingText: 'Recensioni',
                tarifText: '',
                shareText: 'Condividi',
                messageText: '',
                parametreText: 'Impostazioni del profilo',
                voirProfileText: 'Vedi Profilo',
            },
            requestPage : {
                stateText:{
                default : 'Indefinito',
                W:'In Attesa',
                S:'Visto',
                A:'Accettato',
                R:'Rifiutato',
                RT:'Ritardato',
                RD:'Reindirizzato',
                LV:'Consegnato',
                MD:'Modificato',
                PR:'Pronto',
                PI:'Più Info',
                T:'Completato',
                },
                ShareYourselfCard:{
                titleText:'Se non hai ricevuto ordini, puoi utilizzare uno dei seguenti metodi per promuovere il tuo profilo:',
                textOne :'Condividi il tuo profilo sui social media tramite il seguente pulsante',
                textTwo : 'Stampa questo poster e attaccalo al tuo ufficio o luogo di lavoro per facilitare l\'accesso dei clienti al tuo profilo tramite la scansione del codice con i loro telefoni',
                btnShare:'Condividi Profilo',
                btnPrint:'Stampa Poster',
                }
            },
            requestInfoPage : {
                tabsCard: {
                one:'Info',
                two : 'Info Cliente'
                },
                stateActionText:{
                UNR : 'Segna come non visto',
                default : 'Indefinito',
                W:'In Attesa',
                S:'Visto',
                A:'Accettato',
                R:'Rifiutato',
                RT:'Ritardato',
                RD:'Reindirizzato',
                LV:'Consegnato',
                MD:'Modificato',
                PR:'Pronto',
                PI:'Più Info',
                T:'Completato',
                },
                reponseText:'Risposte',
                userCard: {
                title : 'Info Cliente',
                nom:'Nome',
                age:'Età',
                Phone : 'Telefono',
                Gouv : 'Provincia',
                Deleg : 'Distretto',
                saveBtn : 'Salva Cliente',
                cantSave : 'Impossibile salvare il cliente perché stai utilizzando la versione alfa del sistema'
                },
                sendBox: {
                addResponse : 'Aggiungi Risposte Qui'
                }
            },
            imagesPage :{
                title : 'Immagini',
                dontHaveImage : 'Non hai immagini',
                clicForThat : 'Clicca per caricare le immagini',
                deleteBtn : 'Elimina',
                saveBtn : 'Salva',
                toast : {
                    one : 'Sono richieste 5 immagini!',
                    two : 'Sono permesse solo cinque immagini!',
                    three : 'Immagini salvate!',
                    four : 'Immagine eliminata!',
                    five : '',
                }
            },
            systemInscriptionPage:{
                        mainFirstTitle: 'Pagina di abbonamento alla versione completa per',
                        systemNamesData: 'la versione completa di',
                        fullVersionHelpYouText: 'ti aiuta a digitalizzare la tua attività professionale, permettendoti di:',
                        startCard: {
                        subscribeNow: 'Iscriviti ora e ottieni 15 giorni gratuiti per provare il sistema',
                        systemNeedTwoCon: 'Il sistema richiede due condizioni per funzionare:',
                        conditionOne: 'Un computer, che sia portatile o desktop (è preferibile che la RAM sia di almeno 4 GB)',
                        conditionTwo: 'Una connessione Internet (più la connessione è buona, meglio funziona il sistema, e il Wi-Fi è migliore del 3G/4G)',
                        saveSubsRequestButton: 'Salva la richiesta di abbonamento',
                        priceText: 'Prezzo dell\'abbonamento annuale: 500 EUR',
                        },
                        waitingCard: {
                        subsIsRunning: 'Il processo di registrazione per ottenere il sistema richiesto è in corso...',
                        suppText: 'La richiesta è stata ricevuta e ti contatteremo al numero registrato entro 24 ore',
                        },
                        SuccessCard: {
                        congratText: 'Congratulazioni, hai ottenuto il sistema',
                        clicHer: 'Clicca sul link per accedere al sistema',
                        identif: 'Nome utente:',
                        pwdText: 'Password:',
                        youCanText: 'Puoi sempre contattarci per aiutarti a comprendere il sistema e completare il processo di installazione. Ti consigliamo anche di guardare il video sopra',
                        btnText: 'Link al sistema'
                        }

            }
        },
        toolsApps : {
            toolsPage:{

            },
            taxiApp:{

            },
            LouageApp:{

            },
        },
        aboutPage:{
            mainTitle : 'Piattaforma Abyedh',
            subtitle : 'Un\'app per tutto',
            numbers: {
                research: 'Risultati della ricerca',
                visitor : 'Visitatori al mese',
                usersNum: 'Utenti',
                clientNum : 'Clienti',
            },
            platformeObjectText:'L\'obiettivo della piattaforma Abyedh è digitalizzare il maggior numero possibile di attività per gli utenti tunisini al fine di creare un ambiente digitale completo. Lo fa attraverso:',
            directoryContent : {
                title: 'Directory interattiva:',
                text:'Abyedh offre una directory interattiva e un motore di ricerca con oltre 21.000 diverse attività come medici, avvocati, ristoranti, caffè, scuole, hotel, amministrazioni, ecc. Inoltre, fornisce tutte le informazioni necessarie per prendere la decisione corretta per le tue attività quotidiane, come i tempi di viaggio, le opzioni di trasporto e le situazioni amministrative in Tunisia, semplificando così notevolmente la vita dei cittadini tunisini... Puoi anche interagire direttamente con i risultati della ricerca.',
                buttonText:'Scopri'
            },
            systemsContent : {
                title: 'Motore di sistema:',
                text:'Abyedh offre numerosi sistemi di monitoraggio, gestione e contabilità per liberi professionisti, piccole imprese e altri: scuole e università private, medici, avvocati, proprietari di caffè, ristoranti, punti vendita diversi, aziende, ecc. e molti altri sistemi che mirano a migliorare e sviluppare l\'ambiente aziendale e migliorare la qualità dei servizi e dei prodotti utilizzando diverse tecnologie e infrastrutture digitali.',
                buttonText:'Scopri'
            },
            toolsContent : {
                title: 'Set di strumenti creativi:',
                text:'Uno degli obiettivi principali di Abyedh è fornire informazioni e guide adeguate agli utenti tunisini per facilitare il completamento delle loro attività quotidiane e eliminare confusione e complessità legate all\'amministrazione tunisina. Questo avviene attraverso un\'enciclopedia che spiega agli utenti come svolgere varie attività amministrative e legali quotidiane, come ottenere un passaporto, una carta d\'identità, la costituzione di società, l\'ottenimento di un libretto sanitario, ecc., e altre questioni di cui l\'utente ha bisogno nella sua vita quotidiana.',
                buttonText:'Scopri'
            },
            profileContent : {
                title: 'Gestione semplice di tutte le tue attività quotidiane:',
                text:'Anche l\'utente normale può gestire le sue attività quotidiane attraverso il suo account su Abyedh, utilizzando un\'interfaccia digitale che raccoglie tutte le sue attività quotidiane e un calendario organizzato e ricordante. Questo account può anche essere collegato a un conto bancario e a un account amministrativo digitale tunisino per facilitare la gestione delle tue attività quotidiane e risparmiare tempo e fatica.',
                buttonText:'Scopri'
            },
        },


    },
    
}
export default ItaliaTrans